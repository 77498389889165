import { styled } from "@linaria/react";
export const NumberOverlayEditorStyle = styled.div`
    display: flex;
    margin: 6px 0 3px;
    color: var(--gdg-text-dark);

    > input {
        font-size: var(--gdg-editor-font-size);
        padding: 0;
        font-family: var(--gdg-font-family);
        color: var(--gdg-text-dark);
        background-color: var(--gdg-bg-cell);
    }
`;
/*# sourceMappingURL=data:application/json;base64,ewogICJ2ZXJzaW9uIjogMywKICAic291cmNlcyI6IFsiL2hvbWUvcnVubmVyL3dvcmsvZ2xpZGUtZGF0YS1ncmlkL2dsaWRlLWRhdGEtZ3JpZC9wYWNrYWdlcy9jb3JlL3NyYy9kYXRhLWdyaWQtb3ZlcmxheS1lZGl0b3IvcHJpdmF0ZS9udW1iZXItb3ZlcmxheS1lZGl0b3Itc3R5bGUudHN4Il0sCiAgInNvdXJjZXNDb250ZW50IjogWyJpbXBvcnQgeyBzdHlsZWQgfSBmcm9tIFwiQGxpbmFyaWEvcmVhY3RcIjtcblxuZXhwb3J0IGNvbnN0IE51bWJlck92ZXJsYXlFZGl0b3JTdHlsZSA9IHN0eWxlZC5kaXZgXG4gICAgZGlzcGxheTogZmxleDtcbiAgICBtYXJnaW46IDZweCAwIDNweDtcbiAgICBjb2xvcjogdmFyKC0tZ2RnLXRleHQtZGFyayk7XG5cbiAgICA+IGlucHV0IHtcbiAgICAgICAgZm9udC1zaXplOiB2YXIoLS1nZGctZWRpdG9yLWZvbnQtc2l6ZSk7XG4gICAgICAgIHBhZGRpbmc6IDA7XG4gICAgICAgIGZvbnQtZmFtaWx5OiB2YXIoLS1nZGctZm9udC1mYW1pbHkpO1xuICAgICAgICBjb2xvcjogdmFyKC0tZ2RnLXRleHQtZGFyayk7XG4gICAgICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWdkZy1iZy1jZWxsKTtcbiAgICB9XG5gO1xuIl0sCiAgIm1hcHBpbmdzIjogIkFBQUE7QUFFTyxhQUFNLDJCQUEyQixPQUFPO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBOyIsCiAgIm5hbWVzIjogW10KfQo=*/